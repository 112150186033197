<template lang="pug">
	section(:class="{ isTrabalhe }").dropdowns
		div.dropdown(:class="{ ativo : block.ativo }", v-for="block, index in blocks")
			.titulo(@click="toggleAtivo(index)")
				h4(v-html="block.title")
				FontAwesomeIcon(:icon="icons.faChevronDown")
			.block
				component(:is="item.component", :contents="item.contents", v-for="item, indexComp in mappedComponents(index)", :key="`${item.type}-${indexComp}`")
</template>

<script>
import { props, textComponents as components, textDictionary } from '@/mixins/component'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-drop-down",
    props,
    components,
    data() {
        return {
            icons: {
                faChevronDown
            },
            blocks: [],
        }
    },
    mounted() {
        this.blocks = this.contents.content.map((block) => ({ ...block, ativo: false }))
    },
    methods: {
        toggleAtivo(index) {   
            this.blocks[index].ativo = !this.blocks[index].ativo;
        },
		mappedComponents(index) {
            return this.blocks[index].content.map(item => {
                return { ...item, component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
            })
        }
    },
    computed: {
        isTrabalhe() {
			return this.$route.name == 'Trabalhe'
		},
    }
}
</script>

<style lang="stylus" scoped src="./DropDown.styl"></style>
